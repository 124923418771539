import React from 'react'
import {
  Text,
  Heading,
  Container,
  Stack,
  Divider,
  ListItem,
  OrderedList,
  UnorderedList,
  Link,
} from '@chakra-ui/react'
import { graphql } from 'gatsby'
import BaseLayout from '../layout/base'
import SEO from '../components/SEO/PageSEO'
import {
  Heading1,
  Heading2,
  Heading3,
  // Heading4,
  // Heading5,
  // Heading6,
  Paragraph,
} from '../components/ContentWithStyles/TransformToChakra'
// import config from '../../data/SiteConfig'

const Page = ({ location, data }) => {
  // hacer consulta GRAPHQL en lugar de importar
  // hacer consulta GRAPHQL en lugar de importar
  // hacer consulta GRAPHQL en lugar de importar
  // hacer consulta GRAPHQL en lugar de importar

  const email = 'contacto@generadoraleatorio.com'
  // const bussinessName = 'Presencia Digital SL'
  const domain = 'GeneradorAleatorio.com'
  return (
    <BaseLayout>
      <SEO
        title="Generador de números de teléfono aleatorios"
        titleSeo="Generador de números de teléfono aleatorios"
        description="Herramienta para generar números de teléfono al azar en español."
        image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
        // lang="es"
        datePublished="2021-05-11T12:29:33-03:00"
        dateModified="2021-05-11T12:29:33-03:00"
        // breadcrumbs={[{ name: 'Vegetales', url: '/vegetales/' }]}
        permalink={location.pathname}
        // breadcrumbList={[]}
        // noindex={false}
      />
      <Container maxW="5xl">
        <Stack
          // textAlign="center"
          align="center"
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight="110%"
            as="span"
          >
            <Text as="span" color="orange.400">
              Acerca de
            </Text>{' '}
            nosotros
          </Heading>
          <Heading as="h2" color="gray.500" size="md" fontWeight={500}>
            Quiénes somos
          </Heading>
          <Divider />
          <Container maxW="5xl" my={10}>
            <Heading1>Acerca de {domain}</Heading1>
            <Paragraph>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quo
              laboriosam ratione, at quam, error aliquam quis velit modi
              molestiae quasi quidem laudantium consequatur ducimus ut totam
              maiores qui consectetur eum quibusdam ullam odit nobis dolorem?
              Voluptatibus, quo voluptas omnis aliquam animi minima quod,
              exercitationem asperiores facilis sapiente cupiditate eligendi
              dolores.
            </Paragraph>

            <Heading2>Quiénes somos</Heading2>
            <Paragraph>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, id?
            </Paragraph>
            <OrderedList>
              <ListItem>
                <Link color="orange.400" href={`mailto:${email}`}>
                  {email}
                </Link>
              </ListItem>
              <ListItem>Lorem ipsum dolor sit amet.</ListItem>
              <ListItem>Lorem ipsum dolor sit amet.</ListItem>
              <ListItem>Lorem ipsum dolor sit amet.</ListItem>
            </OrderedList>

            <Heading3>Información de contacto</Heading3>
            <Paragraph>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius
              molestiae ratione ipsum?
            </Paragraph>
            <UnorderedList>
              <ListItem>
                <Link color="orange.400" href="/contacto/">
                  Página de contacto
                </Link>
              </ListItem>
              <ListItem>
                <Link color="orange.400" href={`mailto:${email}`}>
                  {email}
                </Link>
              </ListItem>
            </UnorderedList>
          </Container>
        </Stack>
      </Container>
    </BaseLayout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
